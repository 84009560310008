// 默认菜单，没有从后台获取到使用的备用
// 注意， 配置的菜单的idx的最后一位（*/xxx中的xxx）必须具有唯一性
const menus: any = [
  // {
  //   idx: "app",
  //   name: "应用管理",
  //   children: [
  {
    idx: "app/c11",
    name: "应用列表",
    openType: 1,
    path: "/application/list",
    path0: "/application/list",
  },
  // {
  //   idx: "app/c12",
  //   name: "应用详情",
  //   openType: 1,
  //   path: "/application/detail",
  //   path0: "/application/detail",
  // },
  //   ],
  // },
  {
    idx: "ten",
    name: "租户管理",
    children: [
      {
        idx: "ten/c31",
        name: "租户列表",
        openType: 1,
        path: "/tenant/list",
        path0: "/tenant/list",
      },
      {
        idx: "ten/c32",
        name: "租户授权",
        openType: 1,
        path: "/tenant/appauth",
        path0: "/tenant/appauth",
      },
      {
        idx: "ten/c33",
        name: "租户销户",
        openType: 1,
        path: "/tenant/cancellation",
        path0: "/tenant/cancellation",
      },
    ],
  },
  {
    idx: "usr",
    name: "用户管理",
    children: [
      {
        idx: "usr/c21",
        name: "用户列表",
        openType: 1,
        path: "/user/list",
        path0: "/user/list",
      },
      {
        idx: "usr/c22",
        name: "销户申请",
        openType: 1,
        path: "/user/cancellation",
        path0: "/user/cancellation",
      },
    ],
  },
];
//   {
//     icon: null,
//     idx: "/a1",
//     meta: {},
//     name: "Demo",
//     children: [
//       {
//         disable: false,
//         icon: null,
//         idx: "/a1/c1",
//         meta: {},
//         name: "Demo内链",
//         openType: 1,
//         path: "/demo",
//         path0: "/demo",
//       },
//       {
//         disable: false,
//         icon: null,
//         idx: "/a1/c2",
//         meta: {},
//         name: "Demo外链",
//         openType: 1,
//         isLink: true,
//         path: "https://sls.dev1.sims-cn.com/logList?kin=ten",
//         path0: "/logList",
//       },
//     ],
//   },
// ];
export default menus;
