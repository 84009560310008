// 应用路由
const routers = [
  //========================================================================应用管理
  {
    path: "application",
    name: "Application",
    redirect: "application/list", // 应用管理
    // meta: { title: "应用管理", parentCom: "Layout2" },
    // children: [],
  },
  {
    path: "application/list",
    name: "ApplicationList", // 应用管理，应用列表
    component: () => import("@/views/Application/AppList.vue"),
  },
  {
    path: "application/detail",
    name: "ApplicationDetail", // 应用管理，应用详情
    component: () => import("@/views/Application/AppDetail.vue"),
  },
  //========================================================================用户管理
  {
    path: "user",
    name: "User",
    redirect: "user/list", // 用户管理
  },
  {
    path: "user/list",
    name: "UserList", // 用户管理，用户列表
    component: () => import("@/views/UserManage/index.vue"),
  },
  {
    path: "user/cancellation",
    name: "UserCancellation", // 用户管理，销户申请
    component: () => import("@/views/UserManage/UserCancellation.vue"),
  },
  //========================================================================租户管理
  {
    path: "tenant",
    name: "Tenant",
    redirect: "tenant/list", // 租户管理
  },
  {
    path: "tenant/list",
    name: "TenantList",
    meta: { title: "租户列表" },
    component: () => import("@/views/Tenant/Tenant.vue"),
  },
  {
    path: "tenant/appauth",
    name: "TenantAppAuth", // 租户管理，租户授权
    component: () => import("@/views/AuthMgt/index.vue"),
  },
  {
    path: "tenant/cancellation",
    name: "TenantCancellation", // 租户管理，租户销户
    component: () => import("@/views/Tenant/TenantCancellation.vue"),
  },
];
export default routers;
